import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Fade, useScrollTrigger } from '@mui/material'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '../components/link'
import ContactSection from '../components/contactSection'
import ProjectSection from '../components/projectSection'
import AboutSection from '../components/aboutSection'
import ServicesSection from '../components/servicesSection'
import WallpaperSection from '../components/wallpaperSection'
import { Projects, Services, Contact, Wallpapers } from '../utils/navLinks'
import { scrollYTop } from '../utils/constants'

import Logo from '../assets/svg/logo.svg'

const IndexPage = () => {
    const { t } = useTranslation(
        'homepage',
        'services',
        'projects',
        'about',
        'contact',
        'miscellaneous',
    )
    const scrollDownTrigger = useScrollTrigger({ threshold: scrollYTop, disableHysteresis: true })

    return (
        <Layout>
            <SEO title="homepage:title" description="homepage:description" />
            <div className="welcomeHeader">
                <Logo className="homepageLogo" />
                <div className="welcomeCTAs">
                    <Button to={Services.path} component={Link} className="headerButton">
                        {t('services:title')}
                    </Button>
                    <Button to={Projects.path} component={Link} className="headerButton">
                        {t('projects:title')}
                    </Button>
                    <Button to={Wallpapers.path} component={Link} className="headerButton">
                        {t('wallpapers:title')}
                    </Button>
                    <Button to={Contact.path} component={Link} className="headerButton">
                        {t('contact:title')}
                    </Button>
                </div>
                <Fade in={!scrollDownTrigger}>
                    <span className="scrollDown">
                        <hr />
                        {t('miscellaneous:scrollDown')}
                    </span>
                </Fade>
            </div>
            <ServicesSection />
            <AboutSection />
            <ProjectSection />
            <WallpaperSection />
            <ContactSection />
        </Layout>
    )
}

export default IndexPage

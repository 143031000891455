import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
import Link from './link'

const WallpaperSection = () => {
    const { t } = useTranslation('wallpapers')
    return (
        <div className="wallpaperSection">
            <h2 className="header">{t('header')}</h2>
            <p className="paragraph">{t('description')} </p>
            <Button
                disableElevation
                to="https://charbz.gumroad.com/l/waves-wallpaper-pack"
                variant="contained"
                className="containedButton"
                component={Link}
            >
                {t('download')}
            </Button>
            <div className="wallpaperGrid">
                <StaticImage
                    alt={t('wallpaper01')}
                    className="wallpaperPhone"
                    src="../assets/images/wallpapers/wallpaper-01.png"
                />
                <StaticImage
                    alt={t('wallpaper02')}
                    className="wallpaperPhone"
                    src="../assets/images/wallpapers/wallpaper-02.png"
                />
                <StaticImage
                    alt={t('wallpaper03')}
                    className="wallpaperPhone"
                    src="../assets/images/wallpapers/wallpaper-03.png"
                />
                <StaticImage
                    alt={t('wallpaper04')}
                    className="wallpaperPhone"
                    src="../assets/images/wallpapers/wallpaper-04.png"
                />
                <StaticImage
                    alt={t('wallpaper05')}
                    className="wallpaperPhone"
                    src="../assets/images/wallpapers/wallpaper-05.png"
                />
            </div>
        </div>
    )
}

export default WallpaperSection

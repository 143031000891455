import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Card, CardContent } from '@mui/material'
import Link from '../link'

const projectPreview = edge => (
    <Link className="projectCard" key={edge.node.id} to={edge.node.frontmatter.slug}>
        <Card square elevation={0}>
            <GatsbyImage
                image={edge.node.frontmatter.image.childImageSharp.gatsbyImageData}
                alt={edge.node.frontmatter.title}
            />
            <CardContent className="projectCardContent">{edge.node.frontmatter.title}</CardContent>
        </Card>
    </Link>
)

export default projectPreview

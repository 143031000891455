import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { useStaticQuery, graphql } from 'gatsby'

import Link from './link'
import { Projects } from '../utils/navLinks'
import projectPreview from './projectPreview/projectPreview'

const ProjectSection = () => {
    const { t, i18n } = useTranslation('projects')
    const data = useStaticQuery(graphql`
        query NonPageQuery {
            allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 4) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "MMMM DD, YYYY")
                            slug
                            title
                            key
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const { edges } = data.allMarkdownRemark
    const Posts = edges
        // eslint-disable-next-line react/prop-types
        .filter(edge => !!edge.node.frontmatter.date && edge.node.frontmatter.key === i18n.language) // You can filter your posts based on some criteria
        .slice(0, 2)
        .map(edge => projectPreview(edge))

    return (
        <div className="pastWorkSection">
            <div className="pastWorkDescription">
                <h2 className="header">{t('pastWork')}</h2>
                <p className="paragraph">{t('description')}</p>
                <Button to={Projects.path} component={Link} className="textButton">
                    {t('projects:viewAll')}
                </Button>
            </div>
            <div className="recentPosts">{Posts}</div>
        </div>
    )
}

export default ProjectSection

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import Link from './link'
import { Projects, Contact } from '../utils/navLinks'

const AboutSection = () => {
    const { t } = useTranslation('about')
    return (
        <div className="aboutSection">
            <div className="profilePictureSurface">
                <StaticImage
                    src="../assets/images/profilePicture.png"
                    alt={t('title')}
                    className="profilePicture"
                />
            </div>
            <div className="aboutDescription">
                <h2 className="header">{t('header')}</h2>
                <p className="paragraph">{t('description')} </p>
                <div>
                    <Button
                        disableElevation
                        to={Projects.path}
                        variant="contained"
                        className="containedButton"
                        component={Link}
                    >
                        {t('projects:viewWork')}
                    </Button>
                    <Button
                        to={Contact.path}
                        component={Link}
                        className="textButton"
                        // endIcon={<ArrowForwardIosIcon />}
                    >
                        {t('contact:contactMe')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AboutSection

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import Link from './link'
import { Services } from '../utils/navLinks'

const ServicesSection = () => {
    const { t } = useTranslation('services')
    return (
        <div className="webSection">
            <div className="webSectionDescription">
                <h2 className="header">{t('webUIUXTitle')}</h2>
                <p className="paragraph">{t('description')}</p>
                <Button to={Services.path} component={Link} className="textButton">
                    {t('learnMore')}
                </Button>
            </div>
            <StaticImage
                src="../assets/images/homepageWebUIUX.png"
                alt={t('webUIUXTitle')}
                className="webSectionImage"
            />
        </div>
    )
}

export default ServicesSection
